import { render, staticRenderFns } from "./Home_m.vue?vue&type=template&id=bc612f0a&scoped=true"
import script from "./Home_m.vue?vue&type=script&lang=js"
export * from "./Home_m.vue?vue&type=script&lang=js"
import style0 from "./Home_m.vue?vue&type=style&index=0&id=bc612f0a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc612f0a",
  null
  
)

export default component.exports