<template>
  <div class="home">
    <div class="content">
      <div class="swiper swiper-container" ref="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="index in 4" :key="index">
            <div class="page1" v-if="index === 1">
              <div class="textArea m-r-18 flex flex-cl">
                <div class="t1">2024年</div>
                <div class="t2">北京自然城市</div>
                <div class="t2">古树观赏指南</div>
                <div class="t3">奥迪中国</div>
                <div class="t3">北京绿化基金会</div>
              </div>
            </div>
            <Page
              v-else-if="index === 2"
              :dotActiveIndex="1"
              title="北京城市代表性古树"
              subtitle="古树之王的故事"
              routePath="/beijing-city-representative-ancient-tree"
              :pageBgImg="page2bg"
              :mainImg="page2MainImg"
              :clickNextPage="clickNextPage"
            >
            </Page>
            <Page
              v-else-if="index === 3"
              :dotActiveIndex="2"
              title="北京古树游览推荐"
              subtitle="古树观赏地图"
              routePath="/beijing-ancient-tree-map"
              :pageBgImg="page3bg"
              :mainImg="page3MainImg"
              :clickNextPage="clickNextPage"
            >
            </Page
            ><Page
              v-else-if="index === 4"
              :dotActiveIndex="3"
              title="古树知识小课堂"
              subtitle="什么是古树"
              routePath="/ancient-tree-knowledge-class?id=what_is_an_ancient_tree"
              :pageBgImg="page4bg"
              :mainImg="page4MainImg"
              :clickNextPage="clickNextPage"
            >
              <div class="page4Bottom flex flex-cl m-t-10 flex-1">
                <div>古树观赏方法</div>

                <div class="m-t-10 imgArea">
                  <img
                    class="img_w_100 img_h_100 img_cover mainImg"
                    src="@/images/trees/2.2潭拓寺帝树王（杨树田-摄).jpg"
                    alt=""
                    @click="gotoPage('/ancient-tree-viewing-methods')"
                  />
                </div>
                <div
                  class="more flex flex-ct"
                  @click="gotoPage('/ancient-tree-viewing-methods')"
                >
                  <img class="img" src="@/images/more_arrow_right.png" alt="" />
                  <div class="p-h-15">点击探索更多</div>

                  <img class="img" src="@/images/more_arrow_left.png" alt="" />
                </div>
              </div>
            </Page>
          </div>
        </div>
      </div>
    </div>

    <nav class="navs">
      <span
        :class="{
          nav: true,
          active: activeIndex === 0
        }"
        @click="gotoHome(0)"
        >指南首页</span
      >
      <span
        :class="{
          nav: true,
          active: activeIndex === 1
        }"
        @click="gotoHome(1)"
        >代表古树</span
      >
      <span
        :class="{
          nav: true,
          active: activeIndex === 2
        }"
        @click="gotoHome(2)"
        >浏览推荐</span
      >
      <span
        :class="{
          nav: true,
          active: activeIndex === 3
        }"
        @click="gotoHome(3)"
        >知识课堂</span
      >
    </nav>
    <div v-show="!showProducer" @click="clickNextPage" class="arrowDown">
      <img class="img" src="@/images/arrow_down.png" alt="" />
    </div>
    <!-- 
    <div v-show="showProducer" class="producer">
      ·奥迪中国与北京绿化基金会联合呈现·
    </div> -->
  </div>
</template>

<script>
// 引入 Swiper 和它的样式文件
import Swiper, { EffectCoverflow } from "swiper";
import "swiper/swiper-bundle.css";
// 由于 Swiper 6 对 ES 模块支持不够好，EffectCoverflow 可能需要以这种方式单独引入
import "swiper/components/effect-coverflow/effect-coverflow.min.css";

import Page from "./Page_m.vue";
import page2bg from "@/images/bg-2.jpg";
import page3bg from "@/images/bg-3.jpg";
import page4bg from "@/images/bg-4.jpg";

Swiper.use([EffectCoverflow]);
export default {
  components: {
    Page
  },
  data() {
    return {
      swiper: null,
      activeIndex: 0,
      page2bg,
      page3bg,
      page4bg,
      page2MainImg: require("@/images/trees/2.1-九搂十八杈（杨树田-摄）.jpg"),
      page3MainImg: require("@/images/gugong/02.故宫蟠龙槐（杨树田-摄）.jpg"),
      page4MainImg: require("@/images/shenmeshigushu/什么是古树.jpg")
    };
  },
  computed: {
    showProducer() {
      return this.activeIndex === 3;
    },
    isFirstPage() {
      return this.activeIndex === 0;
    }
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      direction: "vertical",
      slidesPerView: 1,
      observer: true,
      observeParents: true,
      resizeObserver: true,
      on: {
        slideChange: (e) => {
          this.activeIndex = e.activeIndex;
          let index = 0;
          try {
            index = parseInt(this.$route.query.index || "0");
          } catch (error) {
            index = 0;
          }
          if (index !== e.activeIndex) {
            this.$router.replace({
              query: {
                index: e.activeIndex
              }
            });
          }
        }
      }
    });
    let index = 0;
    try {
      index = parseInt(this.$route.query.index || "0");
    } catch (error) {
      index = 0;
    }
    this.activeIndex = index;
    if (index) {
      this.swiper.slideTo(index);
    }
  },
  methods: {
    clickNextPage() {
      this.swiper.slideNext();
    },
    gotoHome(index) {
      this.swiper.slideTo(index);
    },
    gotoPage(path) {
      this.$router.push(path);
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 13px;

  color: #ffffff;
}
.navs {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .nav {
    font-size: 8px;
    white-space: nowrap;
    text-orientation: upright;
    writing-mode: vertical-rl;
    height: 81px;
    width: 18px;
    text-align: center;
    background: url("~@/images/nav_bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    &.active {
      color: #000000;
      background: url("~@/images/nav_bg_active.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.content {
  height: 100%;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    // background: red;

    /* Center slide text vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.arrowDown,
.producer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 22px;
  z-index: 10;
}
.arrowDown {
  animation: arrow-blink 1.5s infinite;
  .img {
    width: 28px;
  }

  @keyframes arrow-blink {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(0px, 10px);
    }
  }
}
.logo {
  position: absolute;
  left: 50%;
  transform-origin: center;
  transform: translateX(-50%);
  top: 81px;
  z-index: 10;
  width: 137px;
  transition: all 0.3s;
  &.isFirstPage {
    transform: translateX(-100%) scale(2);
  }
  .img {
    width: 100%;
  }
}
.producer {
  font-size: 10px;
  color: #ffffff;
}

.page1 {
  background-image: url(~@/images/bg-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  .textArea {
    margin-bottom: 75px;
    text-align: right;
    .t1 {
      font-size: 13px;
      line-height: 1.2em;
    }
    .t2 {
      font-size: 35px;
      line-height: 1.2em;
    }
    .t3 {
      font-size: 14px;
      line-height: 1.2em;
    }
  }
}

.page4Bottom {
  margin: 10px 0;
  font-size: 13px;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;

  .line {
    width: 93px;
    height: 2px;
    background: #ffffff7e;
    .linedot {
      background: #ffffff;
      height: 2px;
      width: 32px;
    }
  }
  .imgArea {
    position: relative;

    .mainImg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .more {
    height: 53px;
    font-size: 13px;
    color: #a3a3a3;
    background: #fff;
    .img {
      width: 35px;
      height: auto;
    }
  }
}
</style>
