<template>
  <div class="home_pc">
    <img class="logo" src="@/images/pc/奥迪logo.png" alt="" />

    <div style="flex-grow: 81"></div>
    <div class="siteTitle">
      <div class="t1">2024年</div>
      <div class="t2">北京自然城市古树观赏指南</div>
    </div>
    <div style="flex-grow: 117"></div>
    <div class="content" style="flex-grow: 654">
      <div class="swiper swiper-container" ref="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="index in 4" :key="index">
            <Page
              v-if="index === 1"
              title="北京城市代表性古树"
              subtitle="古树之王的故事"
              routePath="/beijing-city-representative-ancient-tree"
              :mainImg="page2MainImg"
            >
            </Page>
            <Page
              v-else-if="index === 2"
              :dotActiveIndex="2"
              title="北京古树游览推荐"
              subtitle="古树观赏地图"
              routePath="/beijing-ancient-tree-map"
              :mainImg="page3MainImg"
            >
            </Page>
            <Page
              v-else-if="index === 3"
              title="古树知识小课堂"
              subtitle="什么是古树"
              routePath="/ancient-tree-knowledge-class?id=what_is_an_ancient_tree"
              :mainImg="page4MainImg"
            />
            <Page
              v-else-if="index === 4"
              title="古树知识小课堂"
              subtitle="古树观赏方法"
              routePath="/ancient-tree-viewing-methods"
              :mainImg="
                require('@/images/trees/2.2潭拓寺帝树王（杨树田-摄).jpg')
              "
            />
          </div>
        </div>

        <div class="swiper-button-prev" ref="swiperButtonPrev"></div>
        <div class="swiper-button-next" ref="swiperButtonNext"></div>
      </div>
    </div>
    <div style="flex-grow: 59"></div>
    <div class="producer">·奥迪中国与北京绿化基金会联合呈现·</div>
    <div style="flex-grow: 50"></div>
  </div>
</template>

<script>
import Page from "./Page_p.vue";

// 引入 Swiper 和它的样式文件
import Swiper, { EffectCoverflow, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
// 由于 Swiper 6 对 ES 模块支持不够好，EffectCoverflow 可能需要以这种方式单独引入
import "swiper/components/effect-coverflow/effect-coverflow.min.css";

Swiper.use([EffectCoverflow, Navigation]);

export default {
  components: {
    Page
  },
  data() {
    return {
      page2MainImg: require("@/images/trees/2.1-九搂十八杈（杨树田-摄）.jpg"),
      page3MainImg: require("@/images/gugong/02.故宫蟠龙槐（杨树田-摄）.jpg"),
      page4MainImg: require("@/images/shenmeshigushu/什么是古树.jpg"),
      swiper: null,
      activeIndex: null
    };
  },
  computed: {
    showProducer() {
      return true;
    }
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      slidesPerView: 3, // 自动计算
      effect: "coverflow",
      centeredSlides: true, // 居中显示
      observer: true,
      observeParents: true,
      resizeObserver: true,
      loop: true,
      // spaceBetween: 150,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 10,
        scale: 0.8,
        modifier: 1,
        slideShadows: true
      },
      navigation: {
        nextEl: this.$refs.swiperButtonPrev,
        prevEl: this.$refs.swiperButtonNext
      },
      on: {
        click: (_, e) => {
          let href = e.target.getAttribute("data-href"); // 获取自定义属性
          if (href) {
            this.gotoPage(href);
          }
        }
      }
    });
  },
  methods: {
    gotoPage(path) {
      this.$router.push(path);
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  }
};
</script>
<style lang="scss" scoped>
.home_pc {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #2c2b2b;
  background-image: url("~@/images/pc/古树观赏指南PC首页.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  .logo {
    position: absolute;
    left: 52px;
    top: 50px;
    height: 97px;
  }
  .siteTitle {
    .t1 {
      font-size: 35px;
    }
    .t2 {
      font-size: 52px;
    }
  }

  .content {
    .swiper {
      width: 100%;
      height: 100%;
      ::v-deep {
        .swiper-button-prev,
        .swiper-button-next {
          width: 40px;
          height: 80px;
        }
        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 40px;
          color: #fff;
        }
      }
    }

    .swiper-slide {
      text-align: center;
      /* Center slide text vertically */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .producer {
    font-size: 19px;
  }
}
</style>
