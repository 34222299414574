var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",style:({ backgroundImage: `url(${_vm.pageBgImg})` })},[_c('div',{staticClass:"flex-1 mainArea",staticStyle:{"flex-grow":"10"}},[_c('div',{staticClass:"container flex-1"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"flex m-t-15 m-h-15"},[_c('div',{staticClass:"part"},[_c('img',{staticClass:"part",attrs:{"src":_vm.partImg}})]),_c('div',{staticClass:"flex-1"})]),(_vm.title)?_c('div',{class:[
            ' flex flex-ct',
            {
              ['m-t-10']: _vm.isLastPage,
              ['m-t-30']: !_vm.isLastPage
            }
          ]},[_c('div',{staticClass:"title p-h-8"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),(_vm.subtitle)?_c('div',{class:[
            'subtitle flex-ct',
            {
              ['m-t-5']: _vm.isLastPage,
              ['m-t-15']: !_vm.isLastPage
            }
          ]},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()]),_c('div',{class:[
          ' imgArea',
          {
            ['m-t-10']: _vm.isLastPage,
            ['m-t-30']: !_vm.isLastPage
          }
        ]},[_c('img',{staticClass:"img_w_100 img_h_100 img_cover mainImg",attrs:{"src":_vm.mainImg,"alt":""},on:{"click":_vm.gotoPage}})]),_c('div',{staticClass:"more flex flex-ct",on:{"click":_vm.gotoPage}},[_c('img',{staticClass:"img",attrs:{"src":require("@/images/more_arrow_right.png"),"alt":""}}),_c('div',{staticClass:"p-h-15"},[_vm._v("点击探索更多")]),_c('img',{staticClass:"img",attrs:{"src":require("@/images/more_arrow_left.png"),"alt":""}})])]),_vm._t("default")],2),_c('div',{staticStyle:{"flex":"0.5"}}),_c('div',{staticClass:"footer m-t-10"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showProducer),expression:"!showProducer"}],staticClass:"arrowDown"},[_c('img',{staticClass:"img",attrs:{"src":require("@/images/arrow_down.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProducer),expression:"showProducer"}],staticClass:"producer"},[_vm._v(" ·奥迪中国与北京绿化基金会联合呈现· ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }