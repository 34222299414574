<template>
  <div class="page" :style="{ backgroundImage: `url(${pageBgImg})` }">
    <div class="flex-1 mainArea" style="flex-grow: 10">
      <div class="container flex-1">
        <div class="top">
          <div class="flex m-t-15 m-h-15">
            <div class="part">
              <img class="part" :src="partImg" />
            </div>
            <div class="flex-1"></div>
          </div>
          <div
            :class="[
              ' flex flex-ct',
              {
                ['m-t-10']: isLastPage,
                ['m-t-30']: !isLastPage
              }
            ]"
            v-if="title"
          >
            <div class="title p-h-8">{{ title }}</div>
          </div>
          <div
            :class="[
              'subtitle flex-ct',
              {
                ['m-t-5']: isLastPage,
                ['m-t-15']: !isLastPage
              }
            ]"
            v-if="subtitle"
          >
            {{ subtitle }}
          </div>
        </div>
        <div
          :class="[
            ' imgArea',
            {
              ['m-t-10']: isLastPage,
              ['m-t-30']: !isLastPage
            }
          ]"
        >
          <img
            class="img_w_100 img_h_100 img_cover mainImg"
            :src="mainImg"
            alt=""
            @click="gotoPage"
          />
          <!-- <img class="img_w_100" :src="pageBgImg" alt="" /> -->
        </div>
        <div class="more flex flex-ct" @click="gotoPage">
          <img class="img" src="@/images/more_arrow_right.png" alt="" />
          <div class="p-h-15">点击探索更多</div>

          <img class="img" src="@/images/more_arrow_left.png" alt="" />
        </div>
      </div>
      <slot></slot>
    </div>
    <div style="flex: 0.5"></div>
    <div class="footer m-t-10">
      <div v-show="!showProducer" class="arrowDown">
        <img class="img" src="@/images/arrow_down.png" alt="" />
      </div>
      <div v-show="showProducer" class="producer">
        ·奥迪中国与北京绿化基金会联合呈现·
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dotActiveIndex: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    routePath: {
      type: String,
      default: ""
    },
    pageBgImg: {
      type: String,
      default: ""
    },
    mainImg: {
      type: String,
      default: ""
    },
    clickNextPage: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    isLastPage() {
      return !!this.$slots.default;
    },
    showProducer() {
      return this.dotActiveIndex === 3;
    },
    partImg() {
      switch (this.dotActiveIndex) {
        case 0:
          return this.part1;
        case 1:
          return this.part1;
        case 2:
          return this.part2;
        case 3:
          return this.part3;

        default:
          return this.part1;
      }
    }
  },
  data() {
    return {
      part1: require("@/images/part1.png"),
      part2: require("@/images/part2.png"),
      part3: require("@/images/part3.png")
    };
  },
  methods: {
    gotoPage() {
      if (this.routePath) {
        this.$router.push(this.routePath);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mainArea {
  margin-top: 126px;
  margin-left: 32px;
  margin-right: 32px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  .container {
    overflow: hidden;
    border-radius: 12px;
    background: rgba($color: #000000, $alpha: 0.4);
    border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
    display: flex;
    flex-direction: column;
  }
  .top {
    .part {
      height: 10px;
    }
    .dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      display: block;
      margin-left: 4px;
      &.active {
        background-color: #40c066;
      }
    }
  }
  .title {
    font-size: 26px;
    color: #ffffff;
    background-image: url("~@/images/page-top-yinhao1.png"),
      url("~@/images/page-top-yinhao2.png");
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    background-size: 7px auto, 7px auto;
  }
  .subtitle {
    font-size: 13px;
    color: #ffffff;
  }
  .line {
    width: 93px;
    height: 2px;
    background: #ffffff7e;
    .linedot {
      background: #ffffff;
      height: 2px;
      width: 32px;
    }
  }
  .imgArea {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;
    .mainImg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .more {
    height: 53px;
    font-size: 13px;
    color: #a3a3a3;
    background: #fff;
    .img {
      width: 35px;
    }
  }
}

.producer {
  text-align: center;
  font-size: 10px;
  padding-bottom: 22px;
}
.arrowDown {
  padding-bottom: 22px;
  opacity: 0;
  .img {
    width: 28px;
  }
}
.footer {
  flex-shrink: 0;
  // height: 150px;
}
// .arrowDown,
// .producer {
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   bottom: 22px;
//   z-index: 10;
// }
</style>
